export function getUsersList() {
  return this.$api.get('admin/manager/list')
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export function getUser(id) {
  return this.$api.get('admin/manager/' + id)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getUsers() {
      return this.$api.get('admin/managers')
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getBlockedUsers() {
      return this.$api.get('admin/managers/blocked')
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createUser(params) {
      return this.$api.post('admin/manager/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getUser,

    updateUser(id, params) {
      return this.$api.post('admin/manager/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    resetPassword(id) {
      return this.$api.post('admin/manager/' + id + '/reset_password')
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    userChangeStatus(id) {
      return this.$api.post('admin/manager/' + id + '/change_status')
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}
